// extracted by mini-css-extract-plugin
export var blockService = "blockService-module--blockService--82d3e";
export var blockServiceBg = "blockService-module--blockServiceBg--965d0";
export var blockServiceContent = "blockService-module--blockServiceContent--1ff23";
export var blockServiceInfo = "blockService-module--blockServiceInfo--e9ead";
export var blockServiceSlider = "blockService-module--blockServiceSlider--c3c57";
export var blockServiceWrap = "blockService-module--blockServiceWrap--8d9c8";
export var darkText = "blockService-module--darkText--abdf9";
export var link = "blockService-module--link--61940";
export var linkPath = "blockService-module--link-path--a6565";
export var reverse = "blockService-module--reverse--531dc";